<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <b-overlay
          :show="ShowRound"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-card>
            <validation-observer ref="simpleRound">
              <b-row>
                <b-col lg="6">
                  <h3>เลือกหวย</h3>
                </b-col>
              </b-row>

              <b-form-group
                label="หวย"
                label-for="customDelimiter"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <v-select
                    v-model="LottoHead"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="LottoList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="(ele) => ele.value"
                    @input="
                      (LottoSubHead = null),
                      FindSublist(LottoHead),
                      CheckRound(),
                      ClearItems(),
                      ClearUserBar()
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="LottoSubList"
                label="ประเภทหวย"
                label-for="customDelimiter"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <v-select
                    v-model="LottoSubHead"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="LottoSubList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="(ele) => ele.value"
                    @input="ClearItems(), CheckRound(), ClearUserBar()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                v-if="
                  ((LottoHead && !LottoSubList) || LottoSubHead) &&
                    Object.keys(RoundData).length === 0
                "
              >
                <div class="alert alert-primary p-1 text-center">
                  <i class="fa fa-folder-open" /> ไม่พบรอบหวย
                </div>
              </div>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        v-if="
          ((LottoHead && !LottoSubList) || LottoSubHead) &&
            Object.keys(RoundData).length > 0 &&
            ShowRound === false
        "
        lg="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="pl-1 pr-1 pt-1">
              <h3>
                <!-- <i
                  class="fab fa-hotjar"
                  style="
                    color: rgb(255, 71, 0);
                    -webkit-text-stroke: 1px rgb(255, 190, 25);
                  "
                /> -->
                หวยรอบ

                วันที่ {{ CalNameDate(RoundData.CloseDate) }} น.

              </h3>
            </div>
          </b-card-body>

          <div class="col-12">
            <div
              id="__BVID__811"
              class="tabs navtab-bg"
            >
              <div class="">
                <br>
                <ul
                  id="__BVID__811__BV_tab_controls_"
                  role="tablist"
                  class="nav nav-tabs nav-justified"
                >
                  <li
                    role="presentation"
                    class="nav-item"
                  >
                    <a
                      id="__BVID__812___BV_tab_button__"
                      role="tab"
                      aria-selected="true"
                      aria-setsize="2"
                      aria-posinset="1"
                      href="#"
                      target="_self"
                      class="nav-link active bg-primary text-white"
                      aria-controls="__BVID__812"
                    >แยกตาม สมาชิก</a>
                  </li><li
                    role="presentation"
                    class="nav-item"
                  >
                    <a
                      id="__BVID__815___BV_tab_button__"
                      role="tab"
                      tabindex="-1"
                      aria-selected="false"
                      aria-setsize="2"
                      aria-posinset="2"
                      href="#"
                      target="_self"
                      class="nav-link"
                      aria-controls="__BVID__815"
                    >แยกตาม ประเภท</a>
                  </li>
                </ul>
              </div>
              <div
                id="__BVID__811__BV_tab_container_"
                class="tab-content"
              >
                <div
                  id="__BVID__812"
                  role="tabpanel"
                  aria-hidden="false"
                  class="tab-pane active"
                  aria-labelledby="__BVID__812___BV_tab_button__"
                >
                  <div class="row">
                    <div class="col-12">
                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body d-flex align-items-center">
                          <a
                            v-for="(item, index) in UserArry"
                            :key="item"
                            aria-current="location"
                            class="mr-1 h5"
                            @click="
                              RoleBack(item), DelUserArry(UserArry, index)
                            "
                          > <strong v-if="index > 0">></strong> <strong style="margin-top: -15px">{{ item.name }}</strong></a>
                        </div>
                      </b-alert>

                    </div>
                    <div
                      v-if="this.switch === false"
                      class="table-responsive col-12"
                    >
                      <table
                        class="
                          table
                          table-bordered
                          table-sm
                          table-hover
                          table-striped
                          tb-bd
                        "
                      >
                        <thead class="thead-dark">
                          <tr>
                            <th
                              rowspan="2"
                              class="text-center align-middle"
                              style="width: 15rem"
                            >
                              ชื่อเข้าใช้งาน
                              <br>
                            </th>
                            <th
                              rowspan="2"
                              class="text-center align-middle"
                            >
                              ระดับ
                            </th>
                            <th
                              rowspan="2"
                              class="text-center align-middle bd-r-w"
                            >
                              ยอดทั้งหมด
                            </th>
                            <th
                              colspan="3"
                              class="text-center bd-r-w"
                            >
                              สมาชิก
                            </th>
                            <th
                              colspan="3"
                              class="text-center bd-r-w"
                            >
                              atarne
                            </th>
                            <th
                              colspan="3"
                              class="text-center"
                            >
                              บริษัท
                            </th>
                          </tr>
                          <tr>
                            <th class="text-center">
                              ส่งออก
                            </th>
                            <th class="text-center">
                              คอม
                            </th>
                            <th class="text-center bd-r-w">
                              รวม
                            </th>
                            <th class="text-center">
                              ถือหุ้น
                            </th>
                            <th class="text-center">
                              คอม
                            </th>
                            <th class="text-center bd-r-w">
                              รวม
                            </th>
                            <th class="text-center">
                              ถือหุ้น
                            </th>
                            <th class="text-center">
                              คอม
                            </th>
                            <th class="text-center">
                              รวม
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- วน -->
                          <tr
                            v-for="item in BetReportData.Data"
                            :key="item"
                          >
                            <td>
                              <button
                                v-if="item.type === 'Member'"
                                type="button"
                                class="btn btn-link"
                                @click="gethistory(item.name)"
                              >
                                {{ item.name }}
                              </button>
                              <button
                                v-else
                                type="button"
                                class="btn btn-link"
                                @click="
                                  GetBetByAgent(item.agent_id),
                                  UserArry.push({
                                    name: item.name,
                                    agent_id: item.agent_id,
                                    type: 'Agent',
                                  })
                                "
                              >
                                {{ item.name }}
                              </button>
                            </td>
                            <td class="text-center">
                              <span
                                v-if="item.type === 'Member'"
                                class="badge badge-primary"
                              >{{ item.type }}</span>
                              <span
                                v-else
                                class="badge badge-success"
                              >{{
                                item.type
                              }}</span>
                            </td>
                            <td class="text-right bd-r-w text-success">
                              {{ Commas(item.total) }}
                            </td>
                            <td
                              class="text-right text-danger"
                            >
                              -{{ Commas(CalMemExport(item.member_export)) }}
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              {{ Commas(CalMemCom(item.member_com)) }}
                            </td>
                            <td
                              class="text-right bd-r-w text-danger"
                            >
                              -{{ Commas(CalMemTotal(item.member_total)) }}
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              {{ Commas(CalAgentHold(item.agent_hold)) }}
                            </td>
                            <td
                              class="text-right text-danger"
                            >
                              -{{ Commas(CalAgentCom(item.agent_com)) }}
                            </td>
                            <td
                              class="text-right bd-r-w text-success"
                            >
                              {{ Commas(CalAgentTotal(item.agent_total)) }}
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              {{ Commas(CalCompanyHold(item.company_hold)) }}
                            </td>
                            <td
                              class="text-right text-danger"
                            >
                              -{{ Commas(CalCompanyCom(item.company_com)) }}
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              {{
                                Commas(CalCompanyTotal(item.company_total, 2))
                              }}
                            </td>
                          </tr>
                          <tr class="tb-bd">
                            <td
                              colspan="2"
                              class="text-center"
                            >
                              <div class="my-2">
                                <b>รวม</b>
                              </div>
                            </td>
                            <td
                              class="
                                text-right
                                font-weight-bold
                                bd-r-w
                                text-success
                              "
                            >
                              {{ Commas(BetReportData.totalAll) }}
                            </td>
                            <td class="text-right font-weight-bold text-danger">
                              -{{ Commas(BetReportData.mem_exportAll) }}
                            </td>
                            <td
                              class="text-right font-weight-bold text-success"
                            >
                              {{ Commas(BetReportData.mem_comAll) }}
                            </td>
                            <td
                              class="
                                text-right
                                font-weight-bold
                                bd-r-w
                                text-danger
                              "
                            >
                              -{{ Commas(BetReportData.mem_totalAll) }}
                            </td>
                            <td
                              class="text-right font-weight-bold text-success"
                            >
                              {{ Commas(BetReportData.agent_holdAll) }}
                            </td>
                            <td class="text-right font-weight-bold text-danger">
                              -{{ Commas(BetReportData.agent_comAll) }}
                            </td>
                            <td
                              class="
                                text-right
                                font-weight-bold
                                bd-r-w
                                text-success
                              "
                            >
                              {{ Commas(BetReportData.agent_totalAll) }}
                            </td>
                            <td
                              class="text-right font-weight-bold text-success"
                            >
                              {{ Commas(BetReportData.com_holdAll) }}
                            </td>
                            <td class="text-right font-weight-bold text-danger">
                              -{{ Commas(BetReportData.com_comAll) }}
                            </td>
                            <td
                              class="text-right font-weight-bold text-success"
                            >
                              {{ Commas(BetReportData.com_totalAll) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      v-if="this.switch === true"
                      class="table-responsive col-12"
                    >
                      <b-table

                        hover
                        responsive
                        class="position-relative items-center"
                        :per-page="perPageHis"
                        :items="itemsGame"
                        :fields="fieldsGame"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                      >
                        <template #cell(index)="data">
                          {{
                            perPageHis * (currentPageHis - 1) + (data.index + 1)
                          }}
                        </template>
                        <template #cell(status)="data">
                          <b-badge
                            :variant="
                              data.item.status === 'confirm'
                                ? 'success'
                                : 'danger'
                            "
                            class="badge-glow"
                          >
                            {{
                              data.item.status === "confirm"
                                ? "ยืนยัน"
                                : "ยกเลิก"
                            }}
                          </b-badge>
                        </template>
                        <template #cell(status_result)="data">
                          <b-badge
                            :variant="
                              data.item.status_result === 'win'
                                ? 'success'
                                : data.item.status_result === 'waiting'
                                  ? 'info'
                                  : data.item.status_result === 'processing'
                                    ? 'warning'
                                    : 'danger'
                            "
                            class="badge-glow"
                          >
                            {{
                              data.item.status_result === "win"
                                ? "ถูกรางวัล"
                                : data.item.status_result === "waiting"
                                  ? "รอผล"
                                  : data.item.status_result === "processing"
                                    ? "กำลังตรวจ"
                                    : "ไม่ถูกรางวัล"
                            }}
                          </b-badge>
                        </template>
                        <template #cell(LottoSubHead)="data">
                          {{
                            data.item.LottoSubHead
                              ? NameLottoSubHead(
                                data.item.LottoHead,
                                data.item.LottoSubHead
                              )
                              : "-"
                          }}
                        </template>
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                          <div class="text-nowrap">
                            <feather-icon
                              :id="`invoice-row-${data.item.id}-preview-icon`"
                              icon="FileTextIcon"
                              size="16"
                              class="mx-1"
                              @click="
                                $router.push({
                                  name: 'dashboard',
                                  params: { id: data.item.id },
                                })
                              "
                            />
                            <b-tooltip
                              title="History Deposit & Withdraw"
                              :target="`invoice-row-${data.item.id}-preview-icon`"
                            />
                          </div>
                        </template>
                      </b-table>

                      <b-card-body
                        class="d-flex justify-content-between flex-wrap pt-0"
                      >
                        <!-- page length -->
                        <b-form-group
                          label="Per Page"
                          label-cols="8"
                          label-align="left"
                          label-size="sm"
                          label-for="sortBySelect"
                          class="text-nowrap mb-md-0 mr-1"
                        >
                          <b-form-select
                            id="perPageSelect"
                            v-model="perPageHis"
                            size="sm"
                            inline
                            :options="pageOptions"
                            @change="gethistory()"
                          />
                        </b-form-group>

                        <!-- pagination -->
                        <div>
                          <b-pagination
                            v-model="currentPageHis"
                            :total-rows="totalRowsGame"
                            :per-page="perPageHis"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                            @input="gethistory()"
                          >
                            <template #prev-text>
                              <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                              />
                            </template>
                            <template #next-text>
                              <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                              />
                            </template>
                          </b-pagination>
                        </div>
                      </b-card-body>
                    </div>
                    <!-- แยกตามประเภท -->
                    <div
                      class="col-12 table-responsive"
                    >
                      <table
                        class="table table-bordered table-sm table-hover mt-3 table-striped tb-bd"
                      >
                        <thead
                          class="thead-dark"
                        >
                          <tr>
                            <th
                              rowspan="2"
                              class="text-center  align-middle"
                            >
                              ประเภท
                            </th>
                            <th
                              rowspan="2"
                              class="text-center  align-middle bd-r-w"
                            >
                              ยอดทั้งหมด
                            </th>
                            <th
                              colspan="3"
                              class="text-center bd-r-w"
                            >
                              สมาชิก
                            </th>
                            <th
                              colspan="3"
                              class="text-center bd-r-w"
                            >
                              atarne
                            </th>
                            <th
                              colspan="3"
                              class="text-center"
                            >
                              บริษัท
                            </th>
                          </tr>
                          <tr>
                            <th
                              class="text-center"
                            >
                              ส่งออก
                            </th>
                            <th
                              class="text-center"
                            >
                              คอม
                            </th>
                            <th
                              class="text-center bd-r-w"
                            >
                              รวม
                            </th>
                            <th
                              class="text-center"
                            >
                              ถือหุ้น
                            </th>
                            <th
                              class="text-center"
                            >
                              คอม
                            </th>
                            <th
                              class="text-center bd-r-w"
                            >
                              รวม
                            </th>
                            <th
                              class="text-center"
                            >
                              ถือหุ้น
                            </th>
                            <th
                              class="text-center"
                            >
                              คอม
                            </th>
                            <th
                              class="text-center"
                            >
                              รวม
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="itemss in BetReportTypeData.Res"
                            :key="itemss"
                          >
                            <td>
                              <div
                                class="mr-2 my-2"
                              >
                                <b>&nbsp;{{ itemss.name_type }}</b>
                              </div>
                            </td>
                            <td
                              class="text-right bd-r-w text-success"
                            >
                              <div
                                class="my-2"
                              >
                                {{ Commas(itemss.total.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-danger"
                            >
                              <div
                                class="my-2"
                              >
                                -{{ Commas(itemss.mem_export.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              <div
                                class="my-2"
                              >
                                {{ Commas(itemss.mem_com.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right bd-r-w text-danger"
                            >
                              <div
                                class="my-2"
                              >
                                -{{ Commas(itemss.mem_total.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              <div
                                class="my-2"
                              >
                                {{ Commas(itemss.agent_hold.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-danger"
                            >
                              <div
                                class="my-2"
                              >
                                -{{ Commas(itemss.agent_com.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right bd-r-w text-success"
                            >
                              <div
                                class="my-2"
                              >
                                {{ Commas(itemss.agent_total.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              <div
                                class="my-2"
                              >
                                {{ Commas(itemss.com_hold.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-danger"
                            >
                              <div
                                class="my-2"
                              >
                                -{{ Commas(itemss.com_com.toFixed(2)) }}
                              </div>
                            </td>
                            <td
                              class="text-right text-success"
                            >
                              <div
                                class="my-2"
                              >
                                {{ Commas(itemss.com_total.toFixed(2)) }}
                              </div>
                            </td>
                          </tr>
                          <!-- รวม -->
                          <tr
                            class="tb-bd"
                          >
                            <td

                              class="text-center "
                            >
                              <div

                                class="my-3"
                              >
                                <b>รวม </b>
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold bd-r-w text-success"
                            >
                              <div

                                class="my-3"
                              >
                                {{ Commas(BetReportTypeData.totalAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-danger"
                            >
                              <div

                                class="my-3"
                              >
                                -{{ Commas(BetReportTypeData.mem_exportAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-success"
                            >
                              <div

                                class="my-3"
                              >
                                {{ Commas(BetReportTypeData.mem_comAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold bd-r-w text-danger"
                            >
                              <div

                                class="my-3"
                              >
                                -{{ Commas(BetReportTypeData.mem_totalAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-success"
                            >
                              <div

                                class="my-3"
                              >
                                {{ Commas(BetReportTypeData.agent_holdAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-danger"
                            >
                              <div

                                class="my-3"
                              >
                                -{{ Commas(BetReportTypeData.agent_comAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold bd-r-w text-success"
                            >
                              <div

                                class="my-3"
                              >
                                {{ Commas(BetReportTypeData.agent_totalAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-success"
                            >
                              <div

                                class="my-3"
                              >
                                {{ Commas(BetReportTypeData.com_holdAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-danger"
                            >
                              <div

                                class="my-3"
                              >
                                -{{ Commas(BetReportTypeData.com_comAll.toFixed(2)) }}
                              </div>
                            </td> <td

                              class="text-right  font-weight-bold text-success"
                            >
                              <div

                                class="my-3"
                              >
                                {{ Commas(BetReportTypeData.com_totalAll.toFixed(2)) }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  id="__BVID__815"
                  role="tabpanel"
                  aria-hidden="true"
                  class="tab-pane"
                  aria-labelledby="__BVID__815___BV_tab_button__"
                  style="display: none"
                />
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  // BButton,
  BPagination,
  BTable,
  BBadge,
  BAlert,
  // BInputGroupPrepend,
  // BFormSelect,
  // BFormDatepicker,
  // BFormTimepicker,
  BOverlay,
  BIconController,
  // BFormSelect,
  // BFormCheckbox,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, between, length, min,
} from '@validations'

export default {
  components: {
    vSelect,
    BAlert,
    // BInputGroupPrepend,
    // BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    // BButton,
    BPagination,
    BTable,
    BBadge,
    // BFormDatepicker,
    // BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BIconController,
    // BFormSelect,
    // BFormCheckbox,
  },
  data() {
    return {
      perPageHis: 10,
      currentPageHis: 1,
      itemsGame: [],
      totalRowsGame: 1,
      asd: 0,
      UserData: JSON.parse(localStorage.getItem('userData')),
      min,
      required,
      integer,
      between,
      length,
      RoundList: [
        { value: 'รวม', text: 'รวม', code: 'รวม' },
        { value: '3 ตัวบน', text: '3 ตัวบน', code: 'u3' },
        { value: '3 ตัวหน้า', text: '3 ตัวหน้า', code: 'd3' },
        { value: '3 ตัวท้าย', text: '3 ตัวท้าย', code: 'b3' },
        { value: '3 ตัวโต๊ด', text: '3 ตัวโต๊ด', code: 't3' },
        { value: '2 ตัวบน', text: '2 ตัวบน', code: 'u2' },
        { value: '2 ตัวล่าง', text: '2 ตัวล่าง', code: 'd2' },
        { value: '2 ตัวโต๊ด(บน)', text: '2 ตัวโต๊ด(บน)', code: 't2' },
        { value: 'วิ่งบน', text: 'วิ่งบน', code: 'u1' },
        { value: 'วิ่งล่าง', text: 'วิ่งล่าง', code: 'd1' },
      ],
      SortList: [
        { value: 1, text: 'คาดคะเนยอดซื้อมาก-น้อย' },
        { value: 2, text: 'คาดคะเนยอดซื้อน้อย-มาก' },
        { value: 3, text: 'คาดคะเนยอดเสียมาก-น้อย' },
        { value: 4, text: 'คาดคะเนยอดเสียน้อย-มาก' },
      ],
      BetListName: null,
      BetListPerpage: 50,
      BetListSort: 1,
      BetListColor: false,
      ShowRound: false,
      show: true,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [50, 100, 200, 250],
      perPage: 5,
      fields: [
        { key: 'type', label: '#' },
        { key: 'buy', label: 'ซื้อ' },
        { key: 'commission', label: 'คอม' },
        { key: 'receive', label: 'รับ' },
        { key: 'pay', label: 'จ่าย' },
        //  { key: 'pay', label: 'จ่าย' },
      ],
      items: [],
      MdRate: false,
      FieldsMdLimit: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'name_type', label: 'ประเภท' },
        { key: '_id', label: 'เลข' },
        { key: 'totalAmount', label: 'ซื้อ' },
        { key: 'totalRate', label: 'อัตราจ่ายเฉลี่ย' },
        { key: 'totalPay', label: 'จ่าย' },
        { key: 'count', label: 'จำนวน' },
      ],
      MdLimitItems: [],
      currentPageLimitItems: 1,
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      StatusResult: true,
      RoundData: {},
      RateData: {},
      RateInput: {
        LottoHead: null,
        LottoSubHead: null,
        RoundID: null,
        U3: 0,
        D3: 0,
        B3: 0,
        T3: 0,
        U2: 0,
        D2: 0,
        T2: 0,
        U1: 0,
        D1: 0,
        BetLimitU3: 0,
        BetLimitD3: 0,
        BetLimitB3: 0,
        BetLimitT3: 0,
        BetLimitU2: 0,
        BetLimitD2: 0,
        BetLimitT2: 0,
        BetLimitU1: 0,
        BetLimitD1: 0,
        PayLimitU3: 0,
        PayLimitD3: 0,
        PayLimitB3: 0,
        PayLimitT3: 0,
        PayLimitU2: 0,
        PayLimitD2: 0,
        PayLimitT2: 0,
        PayLimitU1: 0,
        PayLimitD1: 0,
      },
      BetData: {
        U3: [],
        D3: [],
        B3: [],
        T3: [],
        U2: [],
        D2: [],
        T2: [],
        U1: [],
        D1: [],
      },
      BetDetail: {
        U3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        B3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
      },
      BetList: [],
      Interval: null,
      TimeToRefresh: 60,
      TimeRefresh: momenttz().add(this.TimeToRefresh + 1, 'seconds'),
      DiffRefresh: 0,
      BetReportArry: null,
      BetReportMax: 0,
      BetReportData: null,
      fieldsGame: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'list_id', label: 'โพยที่' },
        { key: 'RoundData.CloseDateName', label: 'งวดที่' },
        {
          key: 'status',
          label: 'สถานะแทง',
        }, // confirm , cancel
        {
          key: 'status_result',
          label: 'สถานะออกหวย',
        },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        {
          key: 'LottoHeadName',
          label: 'หวย',
          // formatter: value => this.NameLottoHead(value) || '-',
        },
        // { key: 'LottoSubHead', label: 'ประเภทหวย' },
        { key: 'bet', label: 'เลข' },
        { key: 'name_type', label: 'ประเภท' },
        {
          key: 'amount',
          label: 'ยอด',
          formatter: value => this.Commas(value),
        },
        { key: 'win_rate', label: 'จ่าย' },
        // {
        //   key: 'startbalance',
        //   label: 'ราคาก่อนแทง',
        //   formatter: value => this.Commas(value),
        // },
        {
          key: 'endbalance',
          label: 'เครดิตหลังแทง',
          formatter: value => this.Commas(value),
        },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => momenttz(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
        },
        // { key: 'actions', label: 'Actions' },
      ],
      his_username: '',
      switch: false,
      UserArry: [],
      BetReportTypeData: {},
      SwitchType: false,
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortRoundList() {
      return this.RoundList.filter(
        f => this.LottoStatus(this.LottoHead, this.LottoSubHead, f.code)
          || f.code === 'รวม',
      ).map(f => ({ text: f.text, value: f.value, code: f.code }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  async created() {
    this.LottoList = await this.GetSublist()
  },
  mounted() {
    this.totalRows = this.items.length
    this.UserArry = []
    this.ClearUserBar()
    // this.getRound()
  },
  methods: {
    ClearUserBar() {
      this.UserArry = []
      const OBJ = {
        name: this.UserData.agent_name,
        agent_id: this.UserData.agent_id,
        type: 'Agent',
      }
      this.UserArry.push(OBJ)
    },
    async RoleBack(data) {
      if (data.type === 'Member') {
        if (data.name !== this.his_username) {
          await this.ClearCal()
          this.switch = false
          this.gethistory(data.name)
        }
      } else {
        await this.ClearCal()
        this.switch = true
        this.GetBetByAgent(data.agent_id)
      }
    },
    DelUserArry(arry, index) {
      // console.log(arry, index)
      let i = 0
      while (i < arry.length) {
        if (i > index) {
          arry.splice(i, 1)
        } else {
          // eslint-disable-next-line no-plusplus
          ++i
        }
      }
    },
    CalMemExport(data) {
      this.MemExport += Number(data)
      return data
    },
    CalMemCom(data) {
      this.MemCom += Number(data)
      return data
    },
    CalMemTotal(data) {
      this.MemTotal += Number(data)
      return data
    },
    CalAgentHold(data) {
      this.AgentHold += Number(data)
      return data
    },
    CalAgentCom(data) {
      this.AgentCom += Number(data)
      return data
    },
    CalAgentTotal(data) {
      this.AgentTotal += Number(data)
      return data
    },
    CalCompanyHold(data) {
      this.ComHold += Number(data)
      return data
    },
    CalCompanyCom(data) {
      this.ComCom += Number(data)
      return data
    },
    CalCompanyTotal(data) {
      this.ComTotal += Number(data)
      return data
    },
    ClearCal() {
      this.MemExport = 0
      this.MemCom = 0
      this.MemTotal = 0
      this.AgentHold = 0
      this.AgentCom = 0
      this.AgentTotal = 0
      this.ComHold = 0
      this.ComCom = 0
      this.ComTotal = 0
      this.switch = false
      // this.UserArry = []
    },
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get(
          'https://api.rmtlotto.com/api/LottoList',
        )
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push({
                text: Res[x].Subhead[c].name,
                value: Res[x].Subhead[c].SubHeadID,
                Data: Res[x].Subhead[c],
              })
            }
          }
          List.push({
            text: Res[x].name,
            value: Res[x].HeadID,
            SubList,
            Data: Res[x],
          })
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(
            ({ value }) => value === Sub,
          )
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    validationBet() {
      this.$refs.validBet.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.SetBet();
        }
      })
    },
    showModal() {
      this.MdRate = true
    },
    CheckRound() {
      if ((this.LottoHead && !this.LottoSubList) || this.LottoSubHead) {
        this.GetRound(this.LottoHead, this.LottoSubHead)
      }
    },
    GetRound(LottoHead, LottoSubHead) {
      this.ShowRound = true
      const params = {
        LottoHead,
        LottoSubHead,
      }
      this.$http
        .get(
          'https://api.rmtlotto.com/api/round/ShowByHeadAndSub',
          {
            params,
          },
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RoundData = ResData.mes
            // eslint-disable-next-line no-underscore-dangle
            this.GetBet(this.RoundData._id)
            // eslint-disable-next-line no-underscore-dangle
            this.GetHisType(this.RoundData._id)
            // this.StartRefresh()
          }
        })
        .catch(error => {
          console.log(error)
          this.ShowRound = false
        })
    },
    GetRate(ID) {
      this.ShowRound = true
      const params = {
        ID,
      }
      this.$http
        .get(
          'https://api.rmtlotto.com/api/ratebyround/ShowByRoundID',
          {
            params,
          },
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RateData = ResData.mes
            this.CalRate(this.RateData)
          }
          // this.ShowRound = false
        })
        .catch(error => {
          console.log(error)
          this.ShowRound = false
        })
    },
    GetBet(RoundID) {
      this.ClearCal()
      this.ShowRound = true
      const params = {
        round_id: RoundID,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/betreportBymember', params
          ,
        )
        .then(response => {
          this.ShowRound = false
          this.BetReportData = response.data
          this.Max = this.BetReportData.length
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetHisType(RoundID) {
      this.BetReportTypeData = {}
      const params = {
        round_id: RoundID,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/betpeportBymemberType', params
          ,
        )
        .then(response => {
          this.BetReportTypeData = response.data
          // this.Max = this.BetReportData.length
        })
        .catch(error => {
          console.log(error)
        })
    },
    GetBetByAgent(AgentID) {
      this.ClearCal()
      this.BetReportData = null
      // this.ShowRound = true
      const params = {
        // eslint-disable-next-line no-underscore-dangle
        round_id: this.RoundData._id,
        agent_id: AgentID,
      }
      this.$http
        .post(
          'https://api.rmtlotto.com/api/history/betreportBymember', params
          ,
        )
        .then(response => {
          // this.ShowRound = false
          this.BetReportData = response.data.Data
          // const OBJ = {
          //   name: response.data.name,
          //   type: response.data.type,
          // }
          // this.UserArry.push(OBJ)
          this.Max = this.BetReportData.length
        })
        .catch(error => {
          console.log(error)
        })
    },
    Betreport(data) {
      this.BetReportArry = data
      // console.log(this.BetReportArry)
      const U3 = data.U3.length
      const D3 = data.D3.length
      const B3 = data.B3.length
      const T3 = data.T3.length
      const U2 = data.U2.length
      const D2 = data.D2.length
      const T2 = data.T2.length
      const U1 = data.U1.length
      const D1 = data.D1.length
      this.BetReportMax = Math.max(U3, D3, B3, T3, U2, D2, T2, U1, D1)
    },
    gethistory(username) {
      this.switch = true
      if (username) {
        this.his_username = username
        const OBJ = {
          name: username,
          type: 'Member',
        }
        this.UserArry.push(OBJ)
      }
      // const index = 0
      // this.ShowRound = true
      const params = {
        page: this.currentPageHis,
        perpage: this.perPageHis,
        // eslint-disable-next-line no-underscore-dangle
        round_id: this.RoundData._id,
      }
      this.$http
        .get(
          `https://api.rmtlotto.com/api/history/play_report/${this.his_username}`,
          { params },
        )
        .then(response => {
          // this.his_username = null
          this.onFilteredHis(response.data)
          // this.ShowRound = false
        })
        .catch(error => {
          // this.ShowRound = false
          console.log(error)
        })
    },
    onFilteredHis(filteredItems) {
      this.itemsGame = filteredItems.Data
      this.totalRowsGame = filteredItems.total
    },
    SubmitRate() {
      this.$http
        .post(
          'https://api.rmtlotto.com/api/ratebyround/store',
          this.RateInput,
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RateData = ResData.mes
            this.CalRate(this.RateData)
            this.Success('บันทึกการตั้งสู้สำเร็จ')
          }
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    FilterBetList() {
      this.SortBetDeatail(this.BetReportArry, '_id', 'asc')
      if (this.BetListSort === 1) {
        this.SortBetDeatail(this.BetReportArry, 'totalAmount', 'desc')
      } else if (this.BetListSort === 2) {
        this.SortBetDeatail(this.BetReportArry, 'totalAmount', 'asc')
      } else if (this.BetListSort === 3) {
        this.SortBetDeatail(this.BetReportArry, 'totalPay', 'desc')
      } else if (this.BetListSort === 4) {
        this.SortBetDeatail(this.BetReportArry, 'totalPay', 'asc')
      }
    },
    CalRate(RateData) {
      this.RateInput.LottoHead = RateData.LottoHead
      this.RateInput.LottoSubHead = RateData.LottoSubHead
      this.RateInput.RoundID = RateData.RoundID
      this.RateInput.U3 = RateData.U3
      this.RateInput.D3 = RateData.D3
      this.RateInput.B3 = RateData.B3
      this.RateInput.T3 = RateData.T3
      this.RateInput.U2 = RateData.U2
      this.RateInput.D2 = RateData.D2
      this.RateInput.T2 = RateData.T2
      this.RateInput.U1 = RateData.U1
      this.RateInput.D1 = RateData.D1
      this.RateInput.BetLimitU3 = RateData.BetLimitU3
      this.RateInput.BetLimitD3 = RateData.BetLimitD3
      this.RateInput.BetLimitB3 = RateData.BetLimitB3
      this.RateInput.BetLimitT3 = RateData.BetLimitT3
      this.RateInput.BetLimitU2 = RateData.BetLimitU2
      this.RateInput.BetLimitD2 = RateData.BetLimitD2
      this.RateInput.BetLimitT2 = RateData.BetLimitT2
      this.RateInput.BetLimitU1 = RateData.BetLimitU1
      this.RateInput.BetLimitD1 = RateData.BetLimitD1
      this.RateInput.PayLimitU3 = RateData.BetLimitU3 * RateData.U3
      this.RateInput.PayLimitD3 = RateData.BetLimitD3 * RateData.D3
      this.RateInput.PayLimitB3 = RateData.BetLimitB3 * RateData.B3
      this.RateInput.PayLimitT3 = RateData.BetLimitT3 * RateData.T3
      this.RateInput.PayLimitU2 = RateData.BetLimitU2 * RateData.U2
      this.RateInput.PayLimitD2 = RateData.BetLimitD2 * RateData.D2
      this.RateInput.PayLimitT2 = RateData.BetLimitT2 * RateData.T2
      this.RateInput.PayLimitU1 = RateData.BetLimitU1 * RateData.U1
      this.RateInput.PayLimitD1 = RateData.BetLimitD1 * RateData.D1
    },
    CalBetDetail(BetData) {
      this.BetDetail.U3.buy = this.SumByKey(BetData.U3, 'totalAmount')
      this.BetDetail.U3.pay = this.CalMax(BetData.U3, 'totalPay')
      this.BetDetail.D3.buy = this.SumByKey(BetData.D3, 'totalAmount')
      this.BetDetail.D3.pay = this.CalMax(BetData.D3, 'totalPay')
      this.BetDetail.B3.buy = this.SumByKey(BetData.B3, 'totalAmount')
      this.BetDetail.B3.pay = this.CalMax(BetData.B3, 'totalPay')
      this.BetDetail.T3.buy = this.SumByKey(BetData.T3, 'totalAmount')
      this.BetDetail.T3.pay = this.CalMax(BetData.T3, 'totalPay')
      this.BetDetail.U2.buy = this.SumByKey(BetData.U2, 'totalAmount')
      this.BetDetail.U2.pay = this.CalMax(BetData.U2, 'totalPay')
      this.BetDetail.D2.buy = this.SumByKey(BetData.D2, 'totalAmount')
      this.BetDetail.D2.pay = this.CalMax(BetData.D2, 'totalPay')
      this.BetDetail.T2.buy = this.SumByKey(BetData.T2, 'totalAmount')
      this.BetDetail.T2.pay = this.CalMax(BetData.T2, 'totalPay')
      this.BetDetail.U1.buy = this.SumByKey(BetData.U1, 'totalAmount')
      this.BetDetail.U1.pay = this.CalMax(BetData.U1, 'totalPay')
      this.BetDetail.D1.buy = this.SumByKey(BetData.D1, 'totalAmount')
      this.BetDetail.D1.pay = this.CalMax(BetData.D1, 'totalPay')
      this.BetDetail.U3.com = (this.BetDetail.U3.buy * this.UserData.discount) / 100
      this.BetDetail.D3.com = (this.BetDetail.D3.buy * this.UserData.discount) / 100
      this.BetDetail.B3.com = (this.BetDetail.B3.buy * this.UserData.discount) / 100
      this.BetDetail.T3.com = (this.BetDetail.T3.buy * this.UserData.discount) / 100
      this.BetDetail.U2.com = (this.BetDetail.U2.buy * this.UserData.discount) / 100
      this.BetDetail.D2.com = (this.BetDetail.D2.buy * this.UserData.discount) / 100
      this.BetDetail.T2.com = (this.BetDetail.T2.buy * this.UserData.discount) / 100
      this.BetDetail.U1.com = (this.BetDetail.U1.buy * this.UserData.discount) / 100
      this.BetDetail.D1.com = (this.BetDetail.D1.buy * this.UserData.discount) / 100
      this.BetDetail.U3.revenue = this.BetDetail.U3.buy - this.BetDetail.U3.com
      this.BetDetail.D3.revenue = this.BetDetail.D3.buy - this.BetDetail.D3.com
      this.BetDetail.B3.revenue = this.BetDetail.B3.buy - this.BetDetail.B3.com
      this.BetDetail.T3.revenue = this.BetDetail.T3.buy - this.BetDetail.T3.com
      this.BetDetail.U2.revenue = this.BetDetail.U2.buy - this.BetDetail.U2.com
      this.BetDetail.D2.revenue = this.BetDetail.D2.buy - this.BetDetail.D2.com
      this.BetDetail.T2.revenue = this.BetDetail.T2.buy - this.BetDetail.T2.com
      this.BetDetail.U1.revenue = this.BetDetail.U1.buy - this.BetDetail.U1.com
      this.BetDetail.D1.revenue = this.BetDetail.D1.buy - this.BetDetail.D1.com
      // this.BetDetail.U3.balance = this.BetDetail.U3.revenue - this.BetDetail.U3.pay
      // this.BetDetail.D3.balance = this.BetDetail.D3.revenue - this.BetDetail.D3.pay
      // this.BetDetail.B3.balance = this.BetDetail.B3.revenue - this.BetDetail.B3.pay
      // this.BetDetail.T3.balance = this.BetDetail.T3.revenue - this.BetDetail.T3.pay
      // this.BetDetail.U2.balance = this.BetDetail.U2.revenue - this.BetDetail.U2.pay
      // this.BetDetail.D2.balance = this.BetDetail.D2.revenue - this.BetDetail.D2.pay
      // this.BetDetail.T2.balance = this.BetDetail.T2.revenue - this.BetDetail.T2.pay
      // this.BetDetail.U1.balance = this.BetDetail.U1.revenue - this.BetDetail.U1.pay
      // this.BetDetail.D1.balance = this.BetDetail.D1.revenue - this.BetDetail.D1.pay
      // if (this.BetDetail.U3.pay) {
      //   const InU3 = BetData.U3.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.U3.pay,
      //   )
      //   this.BetDetail.U3.revenue = this.BetDetail.U3.buy - BetData.U3[InU3].totalAmount
      // }
      // if (this.BetDetail.D3.pay) {
      //   const InD3 = BetData.D3.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.D3.pay,
      //   )
      //   this.BetDetail.D3.revenue = this.BetDetail.D3.buy - BetData.D3[InD3].totalAmount
      // }
      // if (this.BetDetail.T3.pay) {
      //   const InT3 = BetData.T3.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.T3.pay,
      //   )
      //   this.BetDetail.T3.revenue = this.BetDetail.T3.buy - BetData.T3[InT3].totalAmount
      // }
      // if (this.BetDetail.U2.pay) {
      //   const InU2 = BetData.U2.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.U2.pay,
      //   )
      //   this.BetDetail.U2.revenue = this.BetDetail.U2.buy - BetData.U2[InU2].totalAmount
      // }
      // if (this.BetDetail.D2.pay) {
      //   const InD2 = BetData.D2.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.D2.pay,
      //   )
      //   this.BetDetail.D2.revenue = this.BetDetail.D2.buy - BetData.D2[InD2].totalAmount
      // }
      // if (this.BetDetail.T2.pay) {
      //   const InT2 = BetData.T2.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.T2.pay,
      //   )
      //   this.BetDetail.T2.revenue = this.BetDetail.T2.buy - BetData.T2[InT2].totalAmount
      // }
      // if (this.BetDetail.U1.pay) {
      //   const InU1 = BetData.U1.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.U1.pay,
      //   )
      //   this.BetDetail.U1.revenue = this.BetDetail.U1.buy - BetData.U1[InU1].totalAmount
      // }
      // if (this.BetDetail.D1.pay) {
      //   const InD1 = BetData.D1.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.D1.pay,
      //   )
      //   this.BetDetail.D1.revenue = this.BetDetail.D1.buy - BetData.D1[InD1].totalAmount
      // }
    },
    SortBetDeatail(Arr, Key, type) {
      if (type === 'asc') {
        Arr.U3.sort((a, b) => a[Key] - b[Key])
        Arr.D3.sort((a, b) => a[Key] - b[Key])
        Arr.B3.sort((a, b) => a[Key] - b[Key])
        Arr.T3.sort((a, b) => a[Key] - b[Key])
        Arr.U2.sort((a, b) => a[Key] - b[Key])
        Arr.D2.sort((a, b) => a[Key] - b[Key])
        Arr.T2.sort((a, b) => a[Key] - b[Key])
        Arr.U1.sort((a, b) => a[Key] - b[Key])
        Arr.D1.sort((a, b) => a[Key] - b[Key])
        return null
      }
      Arr.U3.sort((a, b) => b[Key] - a[Key])
      Arr.D3.sort((a, b) => b[Key] - a[Key])
      Arr.B3.sort((a, b) => b[Key] - a[Key])
      Arr.T3.sort((a, b) => b[Key] - a[Key])
      Arr.U2.sort((a, b) => b[Key] - a[Key])
      Arr.D2.sort((a, b) => b[Key] - a[Key])
      Arr.T2.sort((a, b) => b[Key] - a[Key])
      Arr.U1.sort((a, b) => b[Key] - a[Key])
      Arr.D1.sort((a, b) => b[Key] - a[Key])
      return null
    },
    SumByKey(Arr, Key) {
      if (Arr.length > 0) {
        return Arr.reduce((acc, ele) => acc + ele[Key], 0)
      }
      return 0.0
    },
    CalMax(Arr, Key) {
      if (Arr.length > 0) {
        return Arr.reduce(
          // eslint-disable-next-line
          (acc, ele) => (acc = acc > ele[Key] ? acc : ele[Key]),
          0,
        )
      }
      return 0.0
    },
    // สีใหม่
    // eslint-disable-next-line consistent-return
    ColorReport(amount, limit) {
      const DangerClass = 'table-danger'
      const WarningClass = 'table-warning'
      if (amount >= limit) {
        return DangerClass
      }
      // eslint-disable-next-line no-mixed-operators
      const x = (amount * 100) / limit
      if (x >= 80) {
        return WarningClass
      }
    },
    rowClass(item, type) {
      const DangerClass = 'table-danger'
      const WarningClass = 'table-warning'
      const SuccessClass = 'table-success'
      if (!item || type !== 'row') {
        return
      }
      if (item.RowColor === 4) {
        // eslint-disable-next-line consistent-return
        return SuccessClass
      }
      if (item.RowColor === 3) {
        // eslint-disable-next-line consistent-return
        return DangerClass
      }
      if (item.RowColor === 2) {
        // eslint-disable-next-line consistent-return
        return WarningClass
      }
    },
    CalRow(item) {
      // console.log(item)
      if (item.status_result === 'win') {
        return 4
      }
      if (item.bet_digit === 3) {
        if (item.bet_type === 'UP') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitU3)
        }
        if (item.bet_type === 'DOWN_F') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD3)
        }
        if (item.bet_type === 'DOWN_B') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD3)
        }
        if (item.bet_type === 'TOT') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitT3)
        }
      } else if (item.bet_digit === 2) {
        if (item.bet_type === 'UP') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitU2)
        }
        if (item.bet_type === 'DOWN') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD2)
        }
        if (item.bet_type === 'TOT') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitT2)
        }
      } else if (item.bet_digit === 1) {
        if (item.bet_type === 'UP') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitU1)
        }
        if (item.bet_type === 'DOWN') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD1)
        }
      }
      return 0
    },
    CalRowColor(Amount, BetLimit) {
      if (Amount >= BetLimit) {
        return 3
      }
      if (this.Calpercen(Amount, BetLimit) >= 80) {
        return 2
      }
      return 0
    },
    Calpercen(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(2)
      // return (100 * partialValue) / totalValue
    },
    FindSublist(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      if (Index >= 0) {
        this.StatusResult = true
      }
      this.LottoSubList = this.LottoList[Index].SubList
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    ClearItems() {
      this.RoundData = {}
      this.RateData = {}
      this.BetListName = null
      this.RateInput = {
        LottoHead: null,
        LottoSubHead: null,
        RoundID: null,
        U3: 0,
        D3: 0,
        B3: 0,
        T3: 0,
        U2: 0,
        D2: 0,
        T2: 0,
        U1: 0,
        D1: 0,
        BetLimitU3: 0,
        BetLimitD3: 0,
        BetLimitB3: 0,
        BetLimitT3: 0,
        BetLimitU2: 0,
        BetLimitD2: 0,
        BetLimitT2: 0,
        BetLimitU1: 0,
        BetLimitD1: 0,
        PayLimitU3: 0,
        PayLimitD3: 0,
        PayLimitB3: 0,
        PayLimitT3: 0,
        PayLimitU2: 0,
        PayLimitD2: 0,
        PayLimitT2: 0,
        PayLimitU1: 0,
        PayLimitD1: 0,
      }
      this.BetData = {
        U3: [],
        D3: [],
        B3: [],
        T3: [],
        U2: [],
        D2: [],
        T2: [],
        U1: [],
        D1: [],
      }
      this.BetDetail = {
        U3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        B3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
      }
      this.BetList = []
      this.StopRefresh()
      this.TimeRefresh = momenttz().add(this.TimeToRefresh + 1, 'seconds')
    },
    StopRefresh() {
      clearInterval(this.Interval)
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    CalNameDate(Val) {
      // console.log(Val)
      const MonthTH = [
        '',
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ]
      const Year = Number(momenttz(Val).format('YYYY')) + 543
      const Month = momenttz(Val).format('M')
      const Day = momenttz(Val).format('DD')
      const Time = momenttz(Val).format('HH:mm')

      return `${Day} ${MonthTH[Month]} ${Year} ${Time}`
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}
thead th {
  background-color: #746d94 !important;
  color: #fff;
  text-align: center;
}
thead th {
  padding: 1rem !important;
}

tbody {
  background-color: #f1e8e8;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

/* table {
    border-collapse: collapse;
    letter-spacing: 1px;
  } */
/* th,
  td {
    text-align: left;
    padding: 8px;
  }*/

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
